import React from 'react';
import PropTypes from 'prop-types';
import { noop, filter } from 'lodash';
import {
  FINANCE_CASH_JOURNAL_FORM_FIELDS,
} from '../../../../../constants/finance/finance.constant';
import ListForm from '../../../../../components/ListForm/ListForm.component';

export default function CashJournalFormHeader(props) {
  const {
    form, coa, onFormChange, isDebit, onSearchUnit,
    isReconciliation, onSearchDestinationAccount, user,
  } = props;
  const { value } = form;
  const { tipe = 0 } = value;
  let fields = FINANCE_CASH_JOURNAL_FORM_FIELDS;
  const journalType = filter(fields, o => o.name === 'type');
  const formType = filter(fields, o => o.name === 'tipe');
  const dateSelector = filter(fields, o => o.name === 'date');
  const unitSelector = filter(fields, o => o.name === 'unit');
  const destinationSelector = filter(fields, o => o.name === 'destination_account');
  const fundType = filter(fields, o => o.name === 'fund_type');

  dateSelector[0].disabled = true;
  
  if (!isReconciliation) {
    if (!isDebit) {
      formType[0].data = [
        { label: 'Standard', value: '1' },
      ];

      fundType[0].data = [
        { label: 'BUDGET', value: 'BUDGET' },
      ];
    } else {
      formType[0].data = [
        { label: 'Standard', value: '1' },
        { label: 'Rekonsiliasi', value: '2' },
      ];
      fundType[0].data = [
        { label: 'Anggaran', value: 'BUDGET' },
        { label: 'Non Anggaran', value: 'NON_BUDGET' },
      ];
    }
    journalType[0].disabled = false;
    formType[0].disabled = false;
    unitSelector[0].disabled = false;
    fundType[0].disabled = false;

    if (user.prm_school_units_id && user.prm_school_units_id === 95) {
      dateSelector[0].disabled = false;
    } else {
      dateSelector[0].disabled = true;
    }
  } else {
    journalType[0].disabled = true;
    formType[0].disabled = true;
    dateSelector[0].disabled = true;
    formType[0].data = [
      { label: 'Rekonsiliasi', value: '2' },
    ];
    fundType[0].data = [
      { label: 'Anggaran', value: 'BUDGET' },
    ];
    fundType[0].disabled = true;
    unitSelector[0].disabled = true;
  }


  if (tipe === '2') {
    unitSelector[0].label = (!isDebit) ? 'Unit Sumber' : 'Unit Tujuan';
    unitSelector[0].handleSearchContent = onSearchUnit;
    unitSelector[0].async = true;
    unitSelector[0].disabled = isReconciliation;
    
    fields = filter(fields, field => field.name !== 'pos');
    
    destinationSelector[0].label = (!isDebit) ? 'Kode Akun Sumber' : 'Kode Akun Tujuan';
    destinationSelector[0].disabled = isReconciliation;
    destinationSelector[0].handleSearchContent = onSearchDestinationAccount;
    destinationSelector[0].async = true;
    fundType[0].data = [
      { label: 'Anggaran', value: 'BUDGET' },
    ];
  } else {
    fields = filter(fields, o => o.name !== 'unit' && o.name !== 'destination_account');
  }

  return (
    <ListForm
      form={form}
      coa={coa}
      formFields={fields}
      onFormChange={onFormChange}
    />
  );
}


CashJournalFormHeader.propTypes = {
  form: PropTypes.object,
  coa: PropTypes.object,
  user: PropTypes.object.isRequired,
  onFormChange: PropTypes.func,
  isDebit: PropTypes.bool,
  onSearchUnit: PropTypes.func,
  isReconciliation: PropTypes.bool,
  onSearchDestinationAccount: PropTypes.func,
};

CashJournalFormHeader.defaultProps = {
  form: {},
  coa: {},
  onFormChange: noop,
  isDebit: false,
  onSearchUnit: noop,
  isReconciliation: false,
  onSearchDestinationAccount: noop,
};
