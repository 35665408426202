import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  map, noop, has, find,
} from 'lodash';
import { Checkbox } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';

export default class CashJournalFundRequest extends Component {
  constructor(props) {
    super(props);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this._setRef = this._setRef.bind(this);
    this._setNewState = this._setNewState.bind(this);
    this.state = {
      selected: {
        fund_request: {},
        non_budget: {},
      },
      unchecked: {
        fund_request: {},
        non_budget: {},
      },
      checkedAll: false,
      selectedCoa: props.selectedCoa,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const {
      selectedFundRequestDetails, selectedNonBudgets, nonBudgets,
      fundRequests,
    } = this.props;

    this.setState({
      selected: {
        fund_request: { ...selectedFundRequestDetails },
        non_budget: { ...selectedNonBudgets },
      },
      unchecked: {
        fund_request: {},
        non_budget: {},
      },
      checkedAll:
        Object.keys(fundRequests).length === Object.keys(selectedFundRequestDetails).length
        && Object.keys(nonBudgets).length === Object.keys(selectedNonBudgets).length,
    });
  }

  componentWillUnmount() {
    const { onChange } = this.props;
    onChange(this.state);
  }

  _setRef(el) {
    if (el === null || !el) {
      return;
    }
    const { input } = el;
    const { value } = input;
    this.checkboxRefs[value] = input;
  }

  _setNewState(state = {}, checked = false, detailId = null, type) {
    const {
      fundRequests,
      nonBudgets,
      selectedFundRequestDetails,
      selectedNonBudgets,
    } = this.props;
    const newState = { ...state };

    if (!checked) {
      if (type === 'fund_request' && has(selectedFundRequestDetails, detailId)) {
        newState.unchecked[type][detailId] = { ...selectedFundRequestDetails[detailId], type };
      } else {
        newState.unchecked[type][detailId] = { ...selectedNonBudgets[detailId], type };
      }
      delete newState.selected[type][detailId];
    } else if (type === 'fund_request') {
      newState.selected[type][detailId] = find(fundRequests,
        o => parseInt(o.id, 10) === parseInt(detailId, 10));
      delete newState.unchecked[type][detailId];
    } else {
      newState.selected[type][detailId] = find(nonBudgets,
        o => parseInt(o.id, 10) === parseInt(detailId, 10));
      delete newState.unchecked[type][detailId];
    }

    return newState;
  }

  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;

    Object.keys(detailIds).forEach((detailId) => {
      detailIds[detailId].checked = isCheckedAll;
      newState = this._setNewState(newState, isCheckedAll, detailId, 'fund_request');
      newState = this._setNewState(newState, isCheckedAll, detailId, 'non_budget');
    });

    this.setState(prevState => ({
      ...prevState,
      ...newState,
      checkedAll: target.checked,
    }));
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked, dataset } = target;
    const { type } = dataset;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value, type);

    this.setState({
      ...newState,
    });
  }

  render() {
    const { fundRequests, nonBudgets, fundType } = this.props;
    const { selected, checkedAll } = this.state;
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli',
      'Agustus', 'September', 'Oktober', 'November', 'Desember',
    ];
    return (
      <div className="manage-fund-request__form-table">
        <table cellPadding="0" cellSpacing="0" border="0" className="table">
          <thead>
            <tr>
              { fundType === 'BUDGET' && (
                <>
                  <th>
                    <Checkbox
                      noMargin
                      name="checkAll"
                      onChange={this._onCheckAll}
                      checked={checkedAll}
                    />
                  </th>
                  <th>No.</th>
                  <th>Jenis</th>
                  <th>Periode</th>
                  <th>Kode</th>
                  <th>Uraian</th>
                  <th>Nilai Permohonan Dana</th>
                  <th>Realisasi Bulan Ini</th>
                  <th>Sisa Permohonan Dana</th>
                </>
              )}
              { fundType === 'NON_BUDGET' && (
                <>
                  <th>
                    <Checkbox
                      noMargin
                      name="checkAll"
                      onChange={this._onCheckAll}
                      checked={checkedAll}
                    />
                  </th>
                  <th>No.</th>
                  <th>Jenis</th>
                  <th>Kode</th>
                  <th>Uraian</th>
                  <th>Nilai Permohonan Dana</th>
                  <th>Realisasi Bulan Ini</th>
                  <th>Sisa Permohonan Dana</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {
              map(fundRequests, (fundRequest, idx) => (
                <tr key={`manage-fund-request_transaction__${idx}`}>
                  <td>
                    <Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      data-type="fund_request"
                      name="selectedFundRequest"
                      onChange={this._onCheckDetail}
                      value={fundRequest.id}
                      checked={!!has(selected.fund_request, fundRequest.id)}
                      ref={this._setRef}
                    />
                  </td>
                  <td>
                    {parseInt(idx, 10) + 1}
                  </td>
                  <td>Permohonan Dana</td>
                  <td>
                    {months[fundRequest.periode]}
                  </td>
                  <td>
                    {fundRequest.code}
                  </td>
                  <td>
                    {fundRequest.desc}
                  </td>
                  <td className="nominal">
                    {commaFormatted(fundRequest.recommendation)}
                  </td>
                  <td className="nominal">
                    {commaFormatted(fundRequest.recommendation - fundRequest.remains)}
                  </td>
                  <td className="nominal">
                    {commaFormatted(fundRequest.remains)}
                  </td>
                </tr>
              ))
            }
            {
              map(nonBudgets, (nonBudget, idx) => (
                <tr key={`manage-fund-request_transaction__${idx}`}>
                  <td>
                    <Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      data-type="non_budget"
                      name="selectedFundRequest"
                      onChange={this._onCheckDetail}
                      value={nonBudget.id}
                      checked={!!has(selected.non_budget, nonBudget.id)}
                      ref={this._setRef}
                    />
                  </td>
                  <td>
                    {parseInt(idx, 10) + 1}
                  </td>
                  <td>Non Anggaran</td>
                  <td>
                    {nonBudget.code_of_account}
                  </td>
                  <td>
                    {nonBudget.description}
                  </td>
                  <td className="nominal">
                    {commaFormatted(nonBudget.final_recommendation)}
                  </td>
                  <td className="nominal">
                    {commaFormatted(nonBudget.final_recommendation - nonBudget.remains)}
                  </td>
                  <td className="nominal">
                    {commaFormatted(nonBudget.remains)}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}
CashJournalFundRequest.propTypes = {
  fundRequests: PropTypes.array,
  onChange: PropTypes.func,
  selectedFundRequestDetails: PropTypes.object,
  selectedNonBudgets: PropTypes.object,
  selectedCoa: PropTypes.object,
  nonBudgets: PropTypes.array,
};
CashJournalFundRequest.defaultProps = {
  fundRequests: [],
  onChange: noop,
  selectedFundRequestDetails: {},
  selectedNonBudgets: {},
  selectedCoa: {},
  nonBudgets: [],
};
